<template>
  <v-layout column nowrap style="background: #201F1F">
    <section>
      <v-parallax height="450">
        <v-layout column align-center justify-start>
          <div class="cont-txt-2">
            <p>
              Respaldamos jugadoras, bajo el lema: “Mientras tu juegas nosotros
              <br />
              hacemos todo lo demás” este lema nace gracias a la inquietud que
              tenemos <br />
              por mejorar la imagen, el desempeño,la administración y la gestión
              de cada <br />
              jugadora.<br /><br />
              Ya que mientras las jugadores hacen lo que más les gusta nosotros
              le<br />
              sacamos provecho. Mientras entrenan nosotros vemos emoción,
              <br />mientras juegan nosotros vemos afición,mientras celebran
              nosotros vemos<br />
              hermandad, y cada uno de estos puntos nos ayudan a crear perfiles,
              y los <br />perfiles nos ayudan a llegar a marcas internacionales.
            </p>
          </div>
        </v-layout>
      </v-parallax>
    </section>
  </v-layout>
</template>

<script>
export default {};
</script>

<style>
.cont-txt-2 {
  width: 60%;
  text-align: center;
}
@font-face {
  font-family: avenirNext;
  src: url("../assets/Avenir-Next-LT-Pro_5196.ttf");
}
.cont-txt-2 p {
  font-family: "avenirNext";
}
@media only screen and (max-width: 768px) {
  .cont-txt-2 {
    width: 90%;
    text-align: justify;
    margin-top: 20%;
  }
  .cont-txt-2 p {
    font-family: "avenirNext";
    font-size: 0.9rem;
  }
}
</style>
