import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VuePageTransition from "vue-page-transition";


Vue.use(VueRouter);
Vue.use(VuePageTransition);
// Vue.config.productionTip = false;


  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'overlay-up' },
  },
 
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
  
})

export default router
