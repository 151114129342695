<template>
  <v-layout column nowrap style="background: #201F1F">
    <section class="cc12">
      <v-layout column align-center>
        <v-card
          class="d-flex flex-column align-center justify-center pb-16 crd-t2"
          elevation="0"
          color="#201F1F"
        >
          <h1 class="title-33 ">Red de jugadoras</h1>
          <span class="spn"></span>
          <div class="cont-txt-2">
            <p class="txt-3 ">
              Hoy tenemos a más de 30 atletas dentro de nuestra red inteligente,
              esta<br />
              red acumula másde 1,000,000 de vistas al día, capaz de transmitir
              un<br />
              mensaje que llegue a miles de personas al mismo tiempo.<br /><br />
              Buscamos a marcas interesadas en el deporte y les ayudamos a crear
              una<br />
              estrategia de impactos reales con efectos positivos en ventas de
              productos,<br />
              awareness e interés de la gente. Cada uno de nuestras jugadoras
              está en<br />
              una continua investigación, para respetar lineamientos y seguir
              creciendo su<br />
              red en un perfil óptimo para equipos y empresas.<br /><br />
              Buscamos que cada jugadora tenga un perfil sobresaliente.
              Nuestros<br />
              expertos están al tanto de cada posteo, cada comentario dentro y
              fuera de<br />
              sus perfiles. En cada juego enfocamos la atención a lo que se dice
              de cada<br />
              jugadora, y compartimos continuamente las mejores jugadas en
              nuestro perfil de Instagram.
            </p>
          </div>
        </v-card>
      </v-layout>
    </section>
  </v-layout>
</template>

<script>
export default {};
</script>

<style>
.txt-3 {
  margin-bottom: 10% !important;
}
.crd-t2 {
  width: 80%;
}
.spn {
  margin-bottom: 5% !important;
}
@media only screen and (max-width: 768px) {
  .crd-t2 {
    width: 100%;
  }
  .txt-3 {
    margin-bottom: 10% !important;
    text-align: justify;
  }
}
</style>
