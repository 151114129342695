<template>
  <v-app class="app" style="overflow-x:hidden">
    <Nav />
    <Home />
    <SecondSection />
    <ThreeSec />
    <ThreeSec2 />
    <FourSec />
    <Form />
  </v-app>
</template>

<script>
import Nav from "./components/Navigation.vue";
import Home from "./views/Home.vue";
import SecondSection from "./views/SecondSection.vue";
import ThreeSec from "./views/ThreeSec.vue";
import ThreeSec2 from "./views/ThreeSec2.vue";
import FourSec from "./views/FourSec.vue";
import Form from "./components/Form.vue";

export default {
  name: "App",

  components: {
    Nav,
    Home,
    SecondSection,
    ThreeSec,
    ThreeSec2,
    FourSec,
    Form,
  },
};
</script>
