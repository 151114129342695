<template>
  <div class="header d-flex align-center justify-space-between ">
    <router-link to="/" class="log-c">
      <img
        src="../img/svg/a atmos nav bar.svg"
        style="position: fixed; margin-top: -0.5rem"
        alt="A"
        class="A"
      />
      <img
        src="../img/svg/logo atmos white_nav bar.svg"
        style="margin-left: 35%;"
      />
    </router-link>

    <div class="social">
      <a
        href="https://www.linkedin.com/company/atmosports/?viewAsMember=true"
        target="_blank"
      >
        <img src="../img/svg/share.svg" alt="share" />
      </a>
      <a href="https://www.facebook.com/Atmos-102815964819609" target="_blank">
        <img src="../img/svg/facebook-logo.svg" alt="facebook" />
      </a>
      <a href="https://www.instagram.com/atmosports/" target="_blank">
        <img src="../img/svg/instagram.svg" alt="instagram" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
};
</script>

<style>
.header {
  width: 40%;
  height: 10vh;
  background-color: transparent;
  position: fixed;
  z-index: 100;
  padding-top: 2rem;
}
.log-c {
  margin-left: 3rem;
  transition: ease-in-out 0.3s;
  z-index: 6;
}
.log-c img:nth-of-type(1) {
  width: 35px;
  height: 35px;
}

.log-c img:nth-of-type(2) {
  margin-left: 5%;
  transition-duration: 0.6s;
  opacity: 0;
  z-index: 1;
}

.log-c:hover img:nth-of-type(2) {
  transform: translate3d(10%, 0px, 0px);
  opacity: 1;
}
.social {
  width: 40%;
  margin-left: 0%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social img {
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 768px) {
  .log-c {
    margin-right: 2rem;
  }
  .log-c img:nth-of-type(1) {
    width: 30px;
    height: 30px;
    margin-left: -1rem;
  }
  .log-c:hover img:nth-of-type(2) {
    transform: none;
    opacity: 0;
  }
  .social {
    /* width: 40%; */
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .social img {
    width: 16px;
    height: 16px;
    margin: 0 0.7rem 0 0.7rem;
  }
}
</style>
