import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VuePageTransition from 'vue-page-transition';
import { gsap } from "gsap";

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  VuePageTransition,
  gsap,
  render: h => h(App)
}).$mount('#app')
