<template>
  <v-layout column nowrap class="white--text">
    <section class="section">
      <v-parallax height="1000" class="pa-0">
        <v-layout column align-center justify-center>
          <div class="home-elements d-flex justify-center">
            <div class="he ">
              <img
                src="../img/svg/circulo top section.svg"
                class="circle-yellow"
              />
              <img src="../img/svg/DOTS home.svg" class="dots" />
            </div>
            <div class="txt-home d-flex flex-column align-center">
              <img
                src="../img/svg/Group 107.svg"
                style=" margin-bottom: 2rem;"
              />
              <p>GESTIÓN DEPORTIVA INTEGRAL</p>
              <div class="scroll">
                <img
                  src="../img/svg/scroll.svg"
                  style="opacity: 0.4; width: 30px; height: 30px; margin-bottom: 1rem"
                />
                <span></span>
              </div>
            </div>
          </div>
          <div class=" d-flex flex-column align-center txt-t">
            <h1 class="tx-a">Somos una agencia enfocada en las jugadoras</h1>
            <span class="little-line"></span>
          </div>
        </v-layout>
      </v-parallax>
    </section>
  </v-layout>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style>
.v-parallax__image-container {
  position: relative !important;
}
.cont-home {
  width: 100vw;
}
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom {
  background: #111111 !important;
  z-index: 100;
  overflow-x: hidden;
  top: 0;
}
.white--text {
  width: 100vw;
  margin: 0;
  padding: 0;
  margin: 0;
  background: #201f1f 0% 0% no-repeat padding-box;
  opacity: 1;
}
.section {
  background-image: url("../img/photos/img-home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: contain;
}
.home-elements {
  position: relative;
  height: 100%;
  width: 100vw;
  z-index: 5;
}
.he {
  width: 100%;
  position: absolute;
  margin-top: 18%;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.circle-yellow {
  height: 76vh;
  margin-top: 18%;
  margin-left: -3%;
  opacity: 0.2;
  z-index: 10;
  position: relative;
}
.dots {
  width: 25vw;
  height: 30vh;
  margin-right: 10%;
  margin-bottom: 20%;
}
.txt-t {
  margin-top: -19%;
}
.little-line {
  width: 3rem;
  height: 2px;
  margin-top: 24%;
  background-color: #ffffff;
}
.txt-home {
  width: 35%;
  margin-top: 15%;
  position: absolute;
  z-index: 5;
}
.txt-home img {
  width: 33vw;
}
.txt-home p {
  font-family: "Nunito", sans-serif;
  letter-spacing: 7.2px;
  color: #fff;
  margin: 0 0 0 10px;
}
.scroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.scroll span {
  width: 1px;
  height: 20rem;
  opacity: 0.4;
  background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .section {
    background-image: url("../img/photos/home-mobile.jpg");
  }
  .he {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .circle-yellow {
    width: 150%;
    margin-top: 40%;
    margin-right: 80%;
  }
  .dots {
    margin-right: -100%;
    margin-bottom: 50%;
    z-index: 20;
    width: 60%;
    height: 30vh;
  }
  .txt-home {
    width: 100%;
    margin-top: 75%;
  }
  .txt-home img {
    width: 60vw;
  }
  .txt-home p {
    font-size: 1rem;
    text-align: center;
  }
  .scroll {
    display: none;
  }
  .txt-t {
    display: flex;
    justify-content: center;
    margin-top: -19%;
  }
  .txt-t .tx-a {
    font-size: 1.2rem;
    margin-right: calc(8% - 1rem);
    text-align: center;
  }
  .little-line {
    margin-top: 20%;
  }
}
</style>
