<template>
  <div class="footer-c d-flex flex-column pt-16" style="background: #201F1F">
    <v-card color="#201F1F" class="mt-10">
      <div class="d-flex" style="width: 100vw">
        <div class="fc mt-4">
          <img src="../img/svg/Mask Group 6.svg" />
        </div>
        <div class="form-cont d-flex justify-center">
          <h2 style="color: #fff; letter-spacing: 3px">Contacto</h2>
          <div class="one-i d-flex">
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Email" />
          </div>
          <textarea type="text" placeholder="Message" />
        </div>
      </div>
      <div class="d-flex btn-c">
        <img src="../img/svg/circulo footer.svg" class="crf" />
        <div class="ftr">
          <button>ENVIAR</button>
          <div class="txt-ftr d-flex flex-column align-center">
            <img
              src="../img/svg/Group 107.svg"
              style="width: 30%; margin-bottom: 6%"
            />
            <a href="" style="font-size: 0.7rem; color: #959595"
              >Términos y condiciones</a
            >
            <p style="font-size: 0.7rem; color: #959595">©2020 ATMOS</p>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style>
.form-cont {
  width: 46vw;
  flex-direction: column;
}
.fc {
  width: 25vw;
}
.fc img {
  width: 25%;
}
.form-cont textarea {
  width: 100%;
  height: 40vh;
  resize: none;
  padding-left: 1rem;
  padding-top: 1rem;
  outline: none;
  font-family: "Roboto", sans-serif;
  color: #707070;
  border: 1px solid #333333;
  background-color: #000000;
}
.one-i {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.one-i input {
  height: 3rem;
  width: 22vw;
  color: #707070;
  font-family: "Roboto", sans-serif;
  border: 1px solid #333333;
  background-color: #000000;
  padding-left: 1rem;
  outline: none;
}
.btn-c {
  width: 100%;
}
.btn-c img {
  width: 25%;
  margin-top: -2rem;
}

.ftr button {
  width: 30%;
  margin-left: 70%;
  margin-top: 2rem;
  padding: 0.8rem 0 0.8rem 0rem;
  background-color: #2b2b2b;
  color: #fff;
  letter-spacing: 5px;
  font-weight: lighter;
  font-size: 0.8rem;
}
.ftr button:hover {
  color: #fea400;
}
.txt-ftr {
  margin-top: 17%;
}
@media only screen and (max-width: 768px) {
  .form-cont {
    width: 100%;
    align-items: center;
  }
  .fc {
    display: none;
  }
  .fc img {
    display: none;
  }
  .one-i {
    flex-direction: column;
    align-items: center !important;
    width: 100%;
  }
  .one-i input {
    height: 3rem;
    width: 100%;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  .form-cont textarea {
    width: 100%;
  }
  .ftr button {
    width: 48%;
    margin-left: 0%;
    margin-top: 1rem;
  }
  .btn-c {
    width: 100%;
    flex-direction: column;
    padding-bottom: 10%;
  }
  .btn-c .crf {
    display: none;
  }
  .txt-ftr {
    margin-top: 45%;
  }
}
</style>
