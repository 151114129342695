<template>
  <v-layout column nowrap style="background: #201F1F">
    <v-layout column align-center>
      <div class="cl-p">
        <v-card
          class=" d-flex justify-center crd-photos pt-10"
          color="#201F1F"
          elevation="0"
        >
          <div class="photos-1">
            <img
              src="../img/photos/celular-1_atmos.png"
              class="one"
              size="30px"
            />
          </div>
          <div class="photos-1 ">
            <img
              src="../img/photos/celular-2-atmos.png"
              size="30px"
              class="c2"
            />
            <span class="line-photos"></span>
          </div>
          <div class="photos-1">
            <img
              src="../img/photos/celular-3-_atmos.png"
              class="three c3"
              size="30px"
            />
          </div>
        </v-card>
      </div>
      <div class="carousel">
        <v-carousel height="100vh" hide-delimiters>
          <v-carousel-item
            reverse-transition="fade-transition"
            transition="fade-transition"
            class="carousel-photo"
          >
            <img src="../img/photos/celular-1_atmos.png" class="one" />
          </v-carousel-item>
          <v-carousel-item
            reverse-transition="fade-transition"
            transition="fade-transition"
            class="carousel-photo"
          >
            <img src="../img/photos/celular-2-atmos.png" class="one" />
          </v-carousel-item>
          <v-carousel-item
            reverse-transition="fade-transition"
            transition="fade-transition"
            class="carousel-photo"
          >
            <img src="../img/photos/celular-3-_atmos.png" class="one" />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
.photos-1 {
  width: 25%;
  display: flex;
}
.crd-photos .photos-1 img {
  width: 100%;
}
.carousel {
  visibility: hidden;
  display: none;
}

.crd-photos .photos-1:nth-of-type(2) {
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
.crd-photos .photos-1:nth-of-type(1) {
  align-items: flex-start;
  justify-content: flex-end;
}
.crd-photos .photos-1:nth-of-type(1) .one {
  margin-top: 25%;
}
.crd-photos .photos-1:nth-of-type(3) {
  justify-content: flex-start;
  align-items: center;
}
.crd-photos .photos-1:nth-of-type(3) .three {
  margin-top: 30%;
}
.photos-1 img {
  width: 100%;
}
.line-photos {
  width: 1px;
  height: 15rem;
  opacity: 0.4;
  background-color: #ffffff;
}
.title-3 {
  font-family: "Nunito", sans-serif;
  letter-spacing: 7.2px;
  color: #fff;
  margin: 0 0 0 10px;
}
h1 {
  color: #fff;
}
.title-33 {
  margin-bottom: 10%;
  margin-top: 5%;
}
.txt-3 {
  color: #fff;
}
.spn {
  width: 3rem;
  height: 1px;
  border-radius: 30px;
  background-color: #ffffff;
  margin-bottom: 2rem;
}
.v-parallax__content {
  color: #ffffff;
  height: 100%;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
}
@media only screen and (max-width: 768px) {
  .cl-p {
    visibility: hidden;
    display: none;
  }
  .carousel {
    visibility: visible;
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .carousel-photo {
    display: flex;
    justify-content: center;
  }
  .carousel-photo img {
    margin-left: 1.5rem;
    width: 85%;
  }
}
</style>
